<div class="notFound__hero">
  <img src="assets/img/404_error.png" alt="Space void" />
  <div class="container-astro">
    <div class="img-astro">
      <a [routerLink]="['/']">
        <img src="assets/img/astronauta.png" alt="Astronaut" />
      </a>
    </div>
  </div>
  <p>Page not Found please click on the astronaut to return to home</p>
</div>
